import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  };

  const getUserName = () => {
    const userName = localStorage.getItem('userName');
    const user = JSON.parse(userName);
    return user?.userName
  };

  const getUserType= () => {
    const userTypeString = localStorage.getItem('userType');
    const userType = JSON.parse(userTypeString);
    return userType?.type
  };

  const [token, setToken] = useState(getToken());
  const [userName, setUserName] = useState(getUserName());
  const [userType, setUserType] = useState(getUserType());

  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  const saveUser = user => {
    localStorage.setItem('userName', JSON.stringify(user));
    setUserName(user.userName);
  };

  const saveTypeUser = userType => {
    localStorage.setItem('userType', JSON.stringify(userType));
    setUserType(userType.type);
  };

  return {
    setToken: saveToken,
    token,
    setUser: saveUser,
    userName,
    setUserType: saveTypeUser,
    userType
  }
}